import { pureState } from "../initial_state.mjs";
import ToolsAndAssistItem from "../types/ToolsAndAssistItem.js";

export default async function fetchData() {
  const response = await fetch("https://drrj37bnjsp5x.cloudfront.net/ta.json", {
    method: "GET",
    headers: {
      ["Accept-Encoding"]: "gzip, deflate, br",
    },
  });
  const result: Array<ToolsAndAssistItem> = await response.json();
  pureState.toolsAndAssist = result;
}
