import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const DEFAULT_LANG = "en";

async function i18nInit(): Promise<void> {
  await i18n.use(initReactI18next).init({
    lng: DEFAULT_LANG,
    fallbackLng: DEFAULT_LANG,
    defaultNS: "cs2",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    ns: ["cs2", "common"],
    resources: {},
  });
  addResources();
}

// Resources are created async as a child process in the background
async function addResources(
  delay = 5e3,
  count = 1,
  maxRetry = 5
): Promise<void> {
  if (count > maxRetry) return;
  try {
    i18n.addResourceBundle(
      "en",
      "cs2",
      // @ts-ignore
      (await import("./en-cs2.json")).default
    );
  } catch {
    setTimeout(() => {
      console.warn("Translation resource retry", count);
      addResources(undefined, count + 1);
    }, delay * 1000);
  }
}

i18nInit();
