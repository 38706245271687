import React from "react";
import Tabs from "./components/Tabs";
import {
  AcademicCapIcon,
  ChatBubbleLeftRightIcon,
  BoltIcon,
  TrophyIcon,
} from "@heroicons/react/20/solid";
import ToolsAndAssistant from "./ToolsAndAssistant.js";
import { Tags } from "./types/Tags.js";
import { useTranslation } from "react-i18next";

function Homepage() {
  const { t } = useTranslation();
  return (
    <>
      <div className="-z-50 absolute top-0 left-0 bg-gradient-to-br from-indigo-900/40 w-full h-full to-90%"></div>
      <div className="container mx-auto subpixel-antialiased py-4 md:py-10">
        <div className="flex flex-row gap-2 items-center text-violet-200 text-2xl md:text-3xl pb-4 md:pb-10">
          <AcademicCapIcon className="h-10 w-10" />
          <div className="tracking-tight">
            <span className="font-semibold">Top Counter Strike 2</span>{" "}
            <span>Tools and Assistants</span>
          </div>
        </div>
        <Tabs
          tabs={[
            {
              title: t("cs2:title.pregame", "Pregame"),
              component: <ToolsAndAssistant filter={Tags.PreGame} />,
              icon: ChatBubbleLeftRightIcon,
            },
            {
              title: t("cs2:title.inGame", "In game"),
              component: <ToolsAndAssistant filter={Tags.InGame} />,
              icon: BoltIcon,
            },
            {
              title: t("cs2:title.postmatch", "Postmatch"),
              component: <ToolsAndAssistant filter={Tags.Postmatch} />,
              icon: TrophyIcon,
            },
          ]}
        />
      </div>
    </>
  );
}

export default Homepage;
