import React from "react";
import Homepage from "./Homepage.jsx";
import { Params } from "wouter";

import fetchToolsAndAssistants from "./fetches/toolsAndAssistants.js";

const routes: Array<Route> = [
  {
    url: "/",
    component: Homepage,
    fetchData: fetchToolsAndAssistants,
  },
];

export default routes;

interface Route {
  url: string;
  component: () => React.JSX.Element;
  fetchData?: (params: Params) => void;
}
