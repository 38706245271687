import React from "react";
import { ArrowTrendingUpIcon, GlobeAltIcon } from "@heroicons/react/20/solid";
import { useSnapshot } from "valtio";
import { pureState } from "./initial_state.mjs";
import ToolsAndAssistItem from "./types/ToolsAndAssistItem.js";
import { Tags } from "./types/Tags.js";
import classNames from "./utils/classNames.mjs";

function Item(data: ToolsAndAssistItem) {
  return (
    <div
      className={classNames(
        "py-4 px-4 gap-4 items-start",
        "md:py-6 md:px-6 md:gap-6 md:items-center flex hover:bg-white/5"
      )}
    >
      <div
        className="rounded-full h-10 w-10 md:h-24 md:w-24 bg-violet-950 shrink-0"
        style={{ backgroundImage: `url(${""})` }}
      />
      <div>
        <div className="flex flex-col gap-2">
          <div className="text-white text-xl">{data.website}</div>
          <div className="text-white flex flex-wrap gap-6 gap-y-2">
            <div className="flex items-center gap-1">
              <GlobeAltIcon className="h-5 w-5 text-violet-400" />{" "}
              {data.globalRank.toLocaleString()}
            </div>
            <div className="flex items-center gap-1">
              <ArrowTrendingUpIcon className="h-5 w-5 text-violet-400" />{" "}
              {data.traffic.toLocaleString()}
            </div>
            <div className="flex items-center gap-2">
              {data.tags.map((i) => (
                <button
                  key={i}
                  className="rounded-full bg-violet-800/[0.50] px-3 text-xs"
                >
                  {i}
                </button>
              ))}
            </div>
          </div>
          <div className="text-indigo-200/50">{data.description}</div>
        </div>
      </div>
    </div>
  );
}
export default function ToolsAndAssistant({ filter }: { filter: Tags }) {
  const state = useSnapshot(pureState);
  const toolsAndAssist: Array<ToolsAndAssistItem> = state.toolsAndAssist;
  const results: Array<ToolsAndAssistItem> = toolsAndAssist.filter((i) =>
    i.tags.includes(filter)
  );
  if (!Array.isArray(results)) return null;
  return (
    <div className="divide-y divide-black/[0.45] divide-solid">
      {results.map((i, idx) => (
        <Item key={idx} {...i} />
      ))}
    </div>
  );
}
