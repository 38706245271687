import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { setup } from "goober";
import routes from "./routes.js";
import { Route, Params } from "wouter";
import "./i18n/i18n.js";

process.env.NODE_ENV === "development"
  ? new EventSource("/esbuild").addEventListener("change", () =>
      location.reload()
    )
  : () => {};

setup(React.createElement);

function App(): React.JSX.Element[] | React.JSX.Element {
  return routes.map((route, idx) => {
    return (
      <Route key={idx} path={route.url}>
        {(params: Params) => {
          if (typeof route.fetchData === "function") route.fetchData(params);
          return <route.component />;
        }}
      </Route>
    );
  });
}

const container: HTMLElement | null = document.getElementById("root");
if (container) ReactDOM.createRoot(container).render(<App />);
