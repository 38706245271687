import { proxy, subscribe } from "valtio";

const initialState = {
  toolsAndAssist: [],
};

export const pureState = proxy(
  JSON.parse(localStorage.getItem("state") ?? JSON.stringify(initialState))
);

subscribe(pureState, function stateSubscription() {
  localStorage.setItem("state", JSON.stringify(pureState));
});
