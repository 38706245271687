import React, { useState } from "react";
import { Tab } from "@headlessui/react";
import { BoltIcon } from "@heroicons/react/20/solid";
import classNames from "../utils/classNames.mjs";

export default function Tabs({
  tabs,
}: {
  tabs: Array<{
    title: string;
    component: React.JSX.Element;
    icon: typeof BoltIcon;
  }>;
}) {
  return (
    <Tab.Group>
      <Tab.List className="flex space-x-1 rounded-xl bg-violet-900/20 p-1">
        {tabs.map((i) => {
          const Icon = i.icon;
          return (
            <Tab
              key={i.title}
              className={({ selected }) =>
                classNames(
                  "flex items-center justify-center text-lg gap-3",
                  "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-violet-500",
                  "ring-white/60 ring-offset-2 ring-offset-violet-400 focus:outline-none focus:ring-2",
                  selected
                    ? "text-white bg-violet-800 shadow"
                    : "text-violet-100 hover:bg-white/[0.12] hover:text-violet-400"
                )
              }
            >
              <Icon className="h-6 h-6" />
              {i.title}
            </Tab>
          );
        })}
      </Tab.List>
      <Tab.Panels className="mt-2">
        {tabs.map((i) => (
          <Tab.Panel
            key={i.title}
            className={classNames(
              "rounded-xl bg-violet-900/25 overflow-hidden",
              "ring-white/60 ring-offset-2 ring-offset-violet-400 focus:outline-none"
            )}
          >
            {i.component}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
}
